import React from "react"
import ContactDetail from "../components/ContactDetail"
import ContactForm from "../components/ContactForm"
import Hero from "../components/Hero"
import Map from "../components/Map"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

import data from "../content/contact.json"

const ContactPage = () => {
  return (
    <Layout>
      <SEO title="Contact" />
      <Hero className="bg-light" data={data.hero} />
      <ContactDetail data={data.contacts} />
      <ContactForm data={data.contactForm} />
      <Map data={data.map} />
    </Layout>
  )
}

export default ContactPage
