import React from "react"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import Icon from "./Icon"
const ContactDetail = ({ data }) => {
  return (
    <section className="pulled-up-section">
      <Container className="text-center">
        <Card className="shadow with-pattern-full">
          <CardBody className="py-5 p-md-5 index-forward">
            <Row>
              {data.map((contact) => (
                <Col md="4" className="mb-4 mb-lg-0" key={contact.name}>
                  <div className="icon-block icon-block-lg mb-4 mx-auto">
                    <Icon icon={contact.icon} className="text-primary" />
                  </div>
                  <h3 className="h4">{contact.name}</h3>
                  <ul
                    className={`list-unstyled mb-0 ${
                      contact.listClass ? contact.listClass : ""
                    }`}
                  >
                    {contact.items.map((item, index) => (
                      <li className="text-muted" key={index}>
                        <a className="reset-anchor" href={item.link}>
                          {item.content}
                        </a>
                      </li>
                    ))}
                  </ul>
                </Col>
              ))}
            </Row>
          </CardBody>
        </Card>
      </Container>
    </section>
  )
}

export default ContactDetail
