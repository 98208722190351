import React from "react"
import { Form, FormGroup, Input, Col, Container, Row, Button } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons"
const ContactForm = ({ data }) => {
  return (
    <section className="py-5">
      <Container className="py-5">
        <Row>
          <Col lg="7" className="mx-auto text-center mb-5">
            <h2>{data.title}</h2>
            <p className="text-muted">{data.content}</p>
            <a className="btn btn-primary" href={data.button.link}>
              <i className="fas fa-globe-americas mr-2"></i>
              {data.button.label}
            </a>
          </Col>
        </Row>
        <Row>
          <Col lg="12" className="mx-auto">
            <Form action="#">
              <Row>
                {data.formInputs.map((input) => (
                  <Col
                    md={input.mdwidth}
                    lg={!input.fullwidth && 4}
                    key={input.id}
                  >
                    <FormGroup>
                      <Input
                        type={input.type}
                        id={input.id}
                        placeholder={input.placeholder}
                        rows={input.type === "textarea" ? 5 : ""}
                      />
                    </FormGroup>
                  </Col>
                ))}
                <Col xs="12" className="text-md-center mb-0">
                  <FormGroup>
                    <Button color="primary" type="submit">
                      <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
                      Send message
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default ContactForm
