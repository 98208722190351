import React, { useState, useEffect, useRef } from "react"
import { Media } from "reactstrap"
import L from "leaflet"
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet"
import UseWindowSize from "../hooks/UseWindowSize"
import "leaflet/dist/leaflet.css"
import Icon from "./Icon"
import MarkerIcon from "../img/marker.png"
const Map = ({ data, className }) => {
  const markerRef = useRef()
  const [focus, setFocus] = useState(false)
  const center = data.center
  const icon =
    typeof window !== "undefined" &&
    L.icon({
      iconUrl: MarkerIcon,
      shadowUrl: "",
      iconSize: [40, 40],
      popupAnchor: [0, -18],
      tooltipAnchor: [0, 19],
    })
  const tileLayers = {
    tiles:
      "https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}{r}.png",
    attribution:
      'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    subdomains: "abcd",
  }
  const [dragging, setDragging] = useState(false)
  const [tap, setTap] = useState(false)

  const size = UseWindowSize()

  useEffect(() => {
    setTap(size.width > 700 ? true : false)
    setDragging(size.width > 700 ? true : false)
  }, [size.width])
  useEffect(() => {
    markerRef && markerRef.current && markerRef.current.openPopup()
  }, [markerRef, icon])
  return (
    <section className="map">
      {typeof window !== "undefined" && (
        <MapContainer
          center={center}
          zoom={12}
          scrollWheelZoom={focus}
          className={`h-100 ${className ? className : ""}`}
          dragging={dragging}
          tap={tap}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        >
          <TileLayer
            url={tileLayers.tiles}
            attribution={tileLayers.attribution}
          />
          <Marker ref={markerRef} position={center} icon={icon}>
            <Popup className="map-custom-popup" maxWidth="400" minWidth="200">
              <div className="text-left p-lg-4">
                <Media className="align-items-center">
                  <div className="icon-block icon-block-lg icon-block-square">
                    <Icon icon="pin-1" className="text-primary" />
                  </div>
                  <Media body className="ml-3">
                    <h5 className="mb-0">{data.popup.title}</h5>
                    <p className="text-small my-0">{data.popup.content}</p>
                  </Media>
                </Media>
              </div>
            </Popup>
          </Marker>
        </MapContainer>
      )}
    </section>
  )
}

export default Map
